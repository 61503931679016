'use strict';

class Mobile {
    /**
     * @param {jQuery} _
     * @param {String} cId
     */
    static init(_, cId) {
        this._          = _;
        this._cId       = cId;
        this._resetting = false;

        this._els = {
            _vehicleClass:      this._('input[name=vehicleClass]'),
            _category:          this._('input[name=category]'),
            _make:              this._('select[name=make]'),
            _model:             this._('select[name=model]'),
            _firstRegistration: this._('select[name=firstRegistration]'),
            _mileage:           this._('select[name=mileage]'),
            _price:             this._('select[name=price]'),

            _conditionNew:  this._('input[name=condition_new]'),
            _conditionUsed: this._('input[name=condition_used]'),
            _usageTypePre:  this._('input[name=usagetype_pre]'),

            _categorySelect: this._('select[name=categorySelect]'),
            _colors:         this._('select[name=colors]'),
            _seats:          this._('select[name=seats]'),
            _doors:          this._('select[name=doors]'),
            _gearbox:        this._('select[name=gearbox]'),
            _power:          this._('select[name=power]'),
            _fuel:           this._('select[name=fuel]'),
            _emissionClass:  this._('select[name=emissionClass]'),

            _alloyWheels:         this._('input[name=alloyWheels]'),
            _navigationSystem:    this._('input[name=navigationSystem]'),
            _parkAssistants:      this._('input[name=parkAssistants]'),
            _electricHeatedSeats: this._('input[name=electricHeatedSeats]'),
            _fourWheelDrive:      this._('input[name=fourWheelDrive]'),
            _tct:                 this._('input[name=tct]'),
            _auxiliaryHeating:    this._('input[name=auxiliaryHeating]'),
            _parkAssistants2:     this._('input[name=parkAssistants2]'),
            _spc:                 this._('input[name=spc]'),

            _sort:           this._('select[name=sort]'),
            _extendedFilter: this._('#extendedFilter'),
            _buttons:        this._('.carfilter button').toArray().map(this._),

            _target: this._('.cur_count')
        };

        this._initCallbacks();
    }

    /**
     * @param {Function} cb
     * @param {boolean}  refresh
     * @param {String}   sort
     */
    static simulateSubmit(cb = undefined, refresh = false, sort = 'default') {
        if (this._resetting) {
            return;
        }

        this._.post('components/mobile/Model.php', {
            contentId:           JSON.stringify(this._cId),
            action:              'simulateSubmit',
            vehicleClass:        JSON.stringify(this._els._vehicleClass.val()),
            category:            JSON.stringify(this._els._category.val()),
            make:                JSON.stringify(this._els._make.val()),
            model:               JSON.stringify(this._els._model.val() ? this._els._model.val() : undefined),
            firstRegistration:   JSON.stringify(this._els._firstRegistration.val()),
            mileage:             JSON.stringify(this._els._mileage.val()),
            price:               JSON.stringify(this._els._price.val()),
            conditionNew:        JSON.stringify(this._els._conditionNew.prop('checked') ? this._els._conditionNew.val() : undefined),
            conditionUsed:       JSON.stringify(this._els._conditionUsed.prop('checked') ? this._els._conditionUsed.val() : undefined),
            usageTypePre:        JSON.stringify(this._els._usageTypePre.prop('checked') ? this._els._usageTypePre.val() : undefined),
            colors:              JSON.stringify(this._els._colors.val().length ? this._els._colors.val() : 'any'),
            seats:               JSON.stringify(this._els._seats.val()),
            doors:               JSON.stringify(this._els._doors.val()),
            gearbox:             JSON.stringify(this._els._gearbox.val()),
            power:               JSON.stringify(this._els._power.val()),
            fuel:                JSON.stringify(this._els._fuel.val().length ? this._els._fuel.val() : 'any'),
            emissionClass:       JSON.stringify(this._els._emissionClass.val()),
            alloyWheels:         JSON.stringify(this._els._alloyWheels.prop('checked') ? true : undefined),
            navigationSystem:    JSON.stringify(this._els._navigationSystem.prop('checked') ? true : undefined),
            parkAssistants:      JSON.stringify(this._els._parkAssistants.prop('checked') ? true : undefined),
            electricHeatedSeats: JSON.stringify(this._els._electricHeatedSeats.prop('checked') ? true : undefined),
            fourWheelDrive:      JSON.stringify(this._els._fourWheelDrive.prop('checked') ? true : undefined),
            tct:                 JSON.stringify(this._els._tct.prop('checked') ? true : undefined),
            auxiliaryHeating:    JSON.stringify(this._els._auxiliaryHeating.prop('checked') ? true : undefined),
            parkAssistants2:     JSON.stringify(this._els._parkAssistants2.prop('checked') ? true : undefined),
            spc:                 JSON.stringify(this._els._spc.prop('checked') ? true : undefined),
            withReturn:          JSON.stringify(cb !== undefined),
            refresh:             JSON.stringify(refresh),
            sort:                JSON.stringify(sort)
        }).then(response => {
            if (cb) {
                cb(response);
            } else {
                this._els._target.html(JSON.parse(response));
            }
        });
    }

    /**
     * @param {boolean} refresh
     * @param {String}  sort
     */
    static handleSubmit(refresh = false, sort = 'default') {
        this.simulateSubmit(response => {
            this._('.fahrzeugsuche .container:nth-child(2)').html(JSON.parse(response));
            return this._updateBorderAndButton();
        }, refresh, sort);
    }

    static reset() {
        this._resetting = true;

        this._els._conditionNew.prop('checked', false);
        this._els._conditionUsed.prop('checked', false);
        this._els._usageTypePre.prop('checked', false).trigger('change');

        this._els._model.val('any');
        this._els._make.val('any').selectric('refresh').trigger('change');
        this._els._firstRegistration.val('any').selectric('refresh');
        this._els._mileage.val('any').selectric('refresh');
        this._els._price.val('any').selectric('refresh');

        this._els._categorySelect.val([]).selectric('refresh');
        this._els._colors.val([]).selectric('refresh');
        this._els._seats.val('any').selectric('refresh');
        this._els._doors.val('any').selectric('refresh');
        this._els._gearbox.val('any').selectric('refresh');
        this._els._power.val('any').selectric('refresh');
        this._els._fuel.val([]).selectric('refresh');
        this._els._emissionClass.val('any').selectric('refresh');

        this._els._alloyWheels.prop('checked', false);
        this._els._navigationSystem.prop('checked', false);
        this._els._parkAssistants.prop('checked', false);
        this._els._electricHeatedSeats.prop('checked', false);
        this._els._fourWheelDrive.prop('checked', false);
        this._els._tct.prop('checked', false);
        this._els._auxiliaryHeating.prop('checked', false);
        this._els._parkAssistants2.prop('checked', false);
        this._els._spc.prop('checked', false);

        this._resetting = false;

        this.simulateSubmit();
    }

    /**
     * @param {HTMLButtonElement} sender
     * @param {boolean}           redirectToFilterPage
     */
    static search(sender, redirectToFilterPage = false) {
        if (redirectToFilterPage) {
            window.location.href = this._(sender).data('target');
        } else {
            this._els._sort.val('default');
            this._els._sort.selectric('refresh');

            this.handleSubmit(true);
        }
    }

    static showExtendedFilter() {
        for (const button of this._els._buttons)
            button.css('visibility', 'hidden');

        this._els._extendedFilter.collapse('show');
    }

    static hideExtendedFilter() {
        for (const button of this._els._buttons)
            button.css('visibility', 'hidden');

        this._els._extendedFilter.collapse('hide');
    }

    /**
     * @returns {Promise<void>}
     */
    static async showMore() {
        await this._.post('components/mobile/Model.php', {
            action: 'showMoreClicked'
        });

        const els = this._('.fahrzeugsuche__item[data-visible=false]').slice(0, 3);
        els.show();
        els.attr('data-visible', true);

        this._updateBorderAndButton();
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    static async _updateBorderAndButton() {
        this._('.fahrzeugsuche__item[data-visible=true]').css('border-bottom', 'solid 3px #06b4c9');
        this._('.fahrzeugsuche__item[data-visible=true]:last').css('border', 'none');

        this._('button.show-more').show();
        if (!this._('.fahrzeugsuche__item[data-visible=false]').length) {
            this._('button.show-more').hide();
        }
    }

    /**
     * @param {jQuery} sender
     * @param {MouseEvent} event
     * @returns {Promise<void>}
     */
    static async adSelected(sender, event) {
        event.preventDefault();

        window.localStorage.removeItem('back_dest');

        await this._.post('components/mobile/Model.php', {
            action: 'setAd',
            ad:     JSON.stringify($(sender).data('ad'))
        });

        window.localStorage.setItem('back_dest', window.location.href);
        window.location.href = sender.href;
    }

    static initBackButton() {
        $('#back_button').prop('href', window.localStorage.getItem('back_dest'));
    }

    /**
     * @private
     */
    static _initCallbacks() {
        const me = this;

        this._(function () {
            if (me._els._usageTypePre.prop('checked')) {
                me._els._conditionNew.prop('disabled', 'disabled');
                me._els._conditionUsed.prop('disabled', 'disabled');
            }
        });

        this._els._make.on('change', async function () {
            me._els._model.empty();
            me._els._model.selectric('destroy');

            const option = $('<option value="any">Modell</option>');
            me._els._model.append(option);

            me.simulateSubmit();

            const response = await $.post('components/mobile/Model.php', {
                action: 'getAllModels',
                make:   JSON.stringify(me._els._make.val())
            }).then(JSON.parse);

            response.filter(r => r.length).forEach(model => {
                const option = $('<option></option>');
                option.val(model.toLowerCase());
                option.html(model);
                me._els._model.append(option);
            });

            me._els._model.selectric('refresh');
        });

        this._els._usageTypePre.on('change', function () {
            if (me._(this).prop('checked')) {
                me._els._conditionNew.prop('disabled', 'disabled');
                me._els._conditionUsed.prop('disabled', 'disabled');
            } else {
                me._els._conditionNew.prop('disabled', '');
                me._els._conditionUsed.prop('disabled', '');
            }
        });

        this._els._sort.on('change', function () {
            me.handleSubmit(true, $(this).val());
        });

        this._els._extendedFilter
            .on('shown.bs.collapse', function () {
                me._els._buttons[2].data('conn-expanded', true);

                me._els._buttons[0].hide();
                me._els._buttons[1].parent().show();
                me._els._buttons[2].parent().hide();
                me._els._buttons[4].parent().show();

                for (const button of me._els._buttons)
                    button.css('visibility', 'visible');
            })
            .on('hidden.bs.collapse', function () {
                me._els._buttons[2].data('conn-expanded', false);

                me._els._buttons[0].show();
                me._els._buttons[1].parent().hide();
                me._els._buttons[2].parent().show();
                me._els._buttons[4].parent().hide();

                for (const button of me._els._buttons)
                    button.css('visibility', 'visible');
            });
    }
}
