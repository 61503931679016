/*
 * Detecting scroll position
 */
function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}


$(document).ready(function(){

    $('#main-nav').doubleTapToGo();

    BrowserDetect.init();
    console.log("You are using " + BrowserDetect.browser + " with version " + BrowserDetect.version);

    $(window).scroll(function() {
        var scroll = getCurrentScroll();
    });

    /*
     * Slick slider (full example)
     */
    $('.slick-slider').slick({
        dots: true,
        arrows: true,
        autoplay: false,
        slidesToShow: 4,
        infinite: true,
        slidesToScroll: 1,
        rows: 0, //fixes bug with unexpected nested div (https://github.com/kenwheeler/slick/issues/3110)
        //swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $('.themepic-slider').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        rows: 0
    });

    $('.init--topoffers-slider').slick({
        dots: true,
        arrows: true,
        autoplay: false,
        slidesToShow: 3,
        infinite: true,
        slidesToScroll: 1,
        adaptiveHeight: true,
        rows: 0, //fixes bug with unexpected nested div (https://github.com/kenwheeler/slick/issues/3110)
        //swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: true
                }
            }
        ]
    });


    var $mainSlider = $('.main-teaser[data-slick-slider="true"]');

    $mainSlider.slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: $mainSlider.data("slidestoshow"),
        infinite: true,
        slidesToScroll: 1,
        rows: 0
    });

/*
    var $teaserBoxSlider = $('.teaser-boxes[data-slick-slider="true"]');

    $teaserBoxSlider.slick({
        dots: true,
        arrows: false,
        autoplay: false,
        slidesToShow: $teaserBoxSlider.data("slidestoshow"),
        infinite: true,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
*/

    /*
     * Custom mobile nav
     */
    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });


    /*
     * Fancybox
     */
    $(".fancybox").magnificPopup({type:'image'});


    // $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    //     disableOn: 700,
    //     type: 'iframe',
    //     mainClass: 'mfp-fade',
    //     removalDelay: 160,
    //     preloader: false,
    //
    //     fixedContentPos: false
    // });

    $('.navbar-toggle').click(function(){
        $('#main-nav').toggleClass('open');
    });

    $('.slider-main').slick({
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 1,
        asNavFor: '.slider-nav',
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1,
                    dots: false,
                    lazyLoad: 'ondemand'
                }
            }
        ]
    });

    $('.slider-nav').slick({
        centerMode: true,
        arrows: true,
        centerPadding: '0',
        slidesToShow: 3,
        asNavFor: '.slider-main',
        focusOnSelect: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 3,
                    focusOnSelect: true,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 3,
                    dots: false,
                    focusOnSelect: true,
                    //lazyLoad: 'ondemand'
                }
            }
        ]
    });

    $('.custom-select').selectric();

});
